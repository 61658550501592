body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}

b {
  font-weight: 600;
}

.breadcrumbs__crumb {
  color: inherit;
  font-size: inherit;
  text-decoration: none;
  font-family: 'Panton  Light', 'Source Sans Pro', 'Roboto', 'Arial', 'sans-serif';
}

.breadcrumbs__crumb:hover {
  color: rgba(255,255,255,1);
  text-decoration: none;
}

.breadcrumbs__separator {
  padding: 0 5px;
}

.menuluokka li {
	color:#000;
}

.luokka div {
	background-color:#1687af;
	border:none;
	color:#ffffff;
	border-radius:unset;
}

button:disabled {
	opacity:0.3;
}

.luokka2 div {
	border:none;
	border-radius:unset;
	z-index:2;
}

.mylabel {
	color:rgba(255,255,255,0.67);
	padding-left:0;
	font-size:0.65rem;
	line-height:1.1;
	padding-top:4px;
	font-family: 'Panton Light', 'Source Sans Pro', 'Roboto', 'Arial', 'sans-serif';

}

.treeitem {
	padding-left:10px;
	margin:1px;
}
.treeitemname {
	background:rgba(255,255,255,0.1);
	color:#fff;
	padding:4px;
}

.dropzone,.treeitemname:hover {
	background:rgba(0,0,0,0.1);
}

